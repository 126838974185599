.components--user--unicorn--profile-box {
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;

  .info-icon {
    margin-left: auto;
  }

  .profile-box-header {
    margin-bottom: 60px;
    color: #59adff;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .profile-box-score-factor {
    color: #4c93ff;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }
}
