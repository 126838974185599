.routes--user--school-classes--available-courses {
  .course-group-outer + .course-group-outer {
    margin-top: 36px;
  }

  .course-group-name-container {
    margin-bottom: 19px;
    font-size: 32px;
    font-weight: bold;
  }

  .course-item {
    + .course-item {
      margin-left: 16px;
    }
  }
}
