@use "stylesheets/variables" as *;

[data-class*="components--front-layout"] {
  &[data-side-bar-menu-opened="false"] .front-layout-actions {
    padding-left: 37px;
  }

  .sub-header-content {
    margin-bottom: 80px;
    color: #595b60;
    font-size: 18px;
  }

  .front-layout-bottom-bar {
    z-index: 1;
    padding: 43px;
    margin-top: auto;
    background: rgba(#fff, .5);
    color: #fff;
  }
}
