@use "stylesheets/variables" as *;

.routes--user--dashboard {
  .greetings-container {
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }

  .explainer-container {
    margin-bottom: 40px;
    color: #595b60;
    font-size: 20px;
    text-align: center;

    @include up-from-lg {
      margin-top: 50px;
      margin-right: 200px;
      margin-left: 200px;
    }
  }

  .new-school-class-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 8px 10px 0 rgba(0 0 0 / 7%);

    .icon-container {
      display: flex;
      width: 60px;
      height: 60px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #4c93ff;
      color: #fff;
      font-size: 36px;
    }

    .new-school-class-label {
      margin-top: 15px;
      color: #a4a5a8;
      font-size: 17px;
    }
  }
}
