.components--score-factor-groups--circle-average {
  .circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle-outer {
    width: 330px;
    height: 330px;
    padding: 25px;
    border-radius: 50%;
    background-color: linear-gradient(180deg, rgba(255 255 255 / 50%) 0%, #4c93ff 100%);
  }

  .circle-inner {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
  }

  .average-container {
    color: #3092ff;
    font-size: 70px;
    font-weight: bold;
  }
}
