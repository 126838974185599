.components--contents--editor--absolute-container {
  position: absolute;
  border: 1px solid #f00;

  &[data-marked-for-destruction="true"] {
    display: none;
  }

  .absolute-container-inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &[data-horizontal-align="left"] {
      display: flex;
      justify-content: start;
    }

    &[data-horizontal-align="center"] {
      display: flex;
      justify-content: center;
    }

    &[data-horizontal-align="right"] {
      display: flex;
      justify-content: end;
    }

    &[data-vertical-align="top"] {
      display: flex;
      align-items: start;
    }

    &[data-vertical-align="center"] {
      display: flex;
      align-items: center;
    }

    &[data-vertical-align="bottom"] {
      display: flex;
      align-items: end;
    }
  }

  .add-absolute-container-button {
    position: absolute;
    top: 4px;
    right: 4px;
    border: 1px solid #000;
    background-color: #fff;
  }
}
