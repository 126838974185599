.components--school-classes--card--add-class-role-circle-button {
  display: flex;
  width: 41px;
  height: 41px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #b7b7b7;

  &:link,
  &:visited {
    color: #000;
  }
}
