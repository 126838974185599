.components--coming-soon {
  position: relative;

  .coming-soon-overlay {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(#000, .5);
  }

  .coming-soon-label-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coming-soon-label {
    display: inline-block;
    border-radius: 8px;
    color: #fff;
    font-size: 130px;
  }

  &[data-small="true"] .coming-soon-label {
    padding: 15px 5px;
    font-size: 58px;
  }
}
