.components--user--class-status--overlay {
  .class-status-overlay-container {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text-content-container {
    position: absolute;
    top: 30%;
    left: 40%;
    max-width: 600px;
  }

  .class-status-overlay-header {
    margin-bottom: 15px;
    font-size: 42px;
  }
}
