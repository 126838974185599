[data-route="user/class-profile"] {
  .coming-soon-container {
    position: relative;
    width: 1245px;
    height: 843px;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .coming-soon-text-container {
    position: absolute;
    top: 300px;
    left: 180px;
  }

  .coming-soon-header {
    font-size: 40px;
  }

  .coming-soon-body {
    max-width: 440px;
  }
}
