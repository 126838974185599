.api-maker-inputs-money {
  display: flex;

  .money-currency {
    padding: 14px;
    border: 1px solid #ddd;
    border-radius: 0;
    border-left: 0;
    font-size: 17px;

    &:focus {
      outline: none;
    }

    &[data-resize="vertical"] {
      resize: vertical;
    }
  }
}
