.components--inputs--button {
  display: inline-block;
  padding: 18px 24px;
  border: 0;
  border-radius: 10px;
  background-color: #fff;
  font-size: 17px;
  text-align: center;
  text-decoration: none;

  &[data-primary="true"] {
    background-color: #4c93ff;
  }

  &[data-dark-primary="true"] {
    background-color: #3670b9;
  }

  &[data-disabled="true"] {
    background-color: #ccc;
    color: #666;
  }

  &[data-primary="true"],
  &[data-dark-primary="true"] {
    color: #fff;

    &:link,
    &:visited {
      color: #fff;
    }
  }

  &[data-small="true"] {
    padding: 9px 12px;
    font-size: 12px;
  }

  &[data-white="true"] {
    background-color: #fff;
    color: #4c93ff;

    &:link,
    &:visited {
      color: #4c93ff;
    }
  }
}
