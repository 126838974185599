.components--front-layout--side-bar-menu-button {
  .menu-button {
    position: absolute;
    z-index: 998;
    top: 19px;
    left: 23px;
  }

  .menu-content {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    display: flex;
    width: 250px;
    height: 100vh;
    flex-direction: column;
    padding: 30px;
    background-color: #0096ff;
    color: #fff;

    overflow-y: auto;
  }

  .menu-item {
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 22px;
  }

  .menu-item-link {
    font-weight: 600;

    &:link,
    &:visited {
      color: #fff;
    }

    &:active,
    &:hover,
    &[data-active="true"] {
      .menu-item-title {
        text-decoration: underline;
      }
    }
  }
}
