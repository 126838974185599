@use "./align-center";
@use "./api-maker-bootstrap-attribute-rows";
@use "./api-maker-bootstrap-card";
@use "./api-maker-inputs-money";
@use "./api-maker-pagination";
@use "./api-maker-table";
@use "./displays";
@use "./invalid-feedback";
@use "./grid";
@use "./margins";
@use "./nunito";
@use "./texts";
@use "./trix";
@use "./widths";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: inherit;
}

html,
body {
  width: 100%;
  height: 1px; // Hack for children to make height 100% work
  min-height: 100%;
  padding: 0;
  margin: 0;
}

// stylelint-disable selector-max-id
.react-root,
html > body > #root {
  width: 100%;
  height: 1px; // Hack for children to make height 100% work
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-attachment: fixed;
  background-image: url("images/skov.jpg");
  background-repeat: no-repeat;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
// stylelint-enable selector-max-id

a:link,
a:visited {
  color: #297eff;
  text-decoration: none;
}

a:active,
a:hover {
  color: #1600ff;
  text-decoration: none;
}
