@use "stylesheets/variables" as *;

[data-component="admin/layout/header"] {
  @include up-to-md {
    .header-actions {
      min-width: 80vw;
      max-width: 100vw;
      background-color: #fff;

      .action-button {
        display: block;
        padding: 11px;

        .fa {
          margin-right: 5px;
        }

        + .action-button {
          border-top: 1px solid #c9c9c9;
        }

        &:link,
        &:visited {
          color: #000;
        }

        &:active,
        &:hover {
          background-color: #dddcf0;
        }
      }
    }
  }

  @include up-from-md {
    .header-actions {
      .action-button {
        display: inline-block;
        padding: 7px 10px;
        border: 1px solid #cbd5e1;
        border-radius: 5px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 13px;

        &:link,
        &:visited {
          color: #000;
        }
      }
    }
  }
}
