.components--user--school-class-courses--show--course-module-box {
  text-align: center;

  .course-module-flex-container {
    display: flex;
    min-height: 300px;
    flex-direction: column;
  }

  .course-module-name {
    color: #5e5f67;
    font-size: 28px;
    font-weight: bold;
  }

  .course-module-expected-minutes {
    margin-top: 18px;
    color: #c2c2c2;
    font-size: 20px;
    font-weight: bold;
  }

  .course-module-appetizer {
    margin-top: 18px;
    font-size: 26px;
  }

  .course-module-actions {
    margin-top: auto;
  }

  .go-through-the-module-again-link {
    text-decoration: underline;
  }

  .course-module-completed-icon-container {
    color: #4c93ff;
    font-size: 80px;
  }
}
