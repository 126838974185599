@use "./variables" as *;

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-sm-2 {
  @include up-from-sm {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-cols-md-1 {
  @include up-from-md {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.grid-cols-lg-2 {
  @include up-from-lg {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.grid-cols-lg-3 {
  @include up-from-lg {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.grid-cols-lg-4 {
  @include up-from-lg {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.grid-cols-xl-3 {
  @include up-from-xl {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.grid-gap-10 {
  grid-gap: 10px;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-sm-1 {
  @include up-from-sm {
    grid-column: span 1 / span 1;
  }
}

.col-span-sm-2 {
  @include up-from-sm {
    grid-column: span 2 / span 2;
  }
}

.col-span-sm-3 {
  @include up-from-sm {
    grid-column: span 3 / span 3;
  }
}

.col-span-md-1 {
  @include up-from-md {
    grid-column: span 1 / span 1;
  }
}

.col-span-md-2 {
  @include up-from-md {
    grid-column: span 2 / span 2;
  }
}

.col-span-lg-1 {
  @include up-from-lg {
    grid-column: span 1 / span 1;
  }
}

.col-span-lg-2 {
  @include up-from-lg {
    grid-column: span 2 / span 2;
  }
}

.col-span-lg-3 {
  @include up-from-lg {
    grid-column: span 3 / span 3;
  }
}

.col-span-xl-1 {
  @include up-from-xl {
    grid-column: span 1 / span 1;
  }
}

.col-span-xl-2 {
  @include up-from-xl {
    grid-column: span 2 / span 2;
  }
}

.col-span-xl-3 {
  @include up-from-xl {
    grid-column: span 3 / span 3;
  }
}
