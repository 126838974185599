.components--survey-step-elements--partial-types--image-options--option-element {
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;

  &[data-checked="false"] {
    background-color: #c5c5c5;

    &:hover {
      background-color: #eee;
    }
  }

  &[data-checked="true"] {
    background-color: #4c93ff;

    &:hover {
      background-color: #3670b9;
    }
  }

  .option-background-image {
    width: 100%;
    height: 90px;
    margin-right: auto;
    margin-left: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .option-icon-container {
    font-size: 36px;
    text-align: center;
  }

  .option-label {
    margin-top: 10px;
    font-weight: bold;
    overflow-wrap: break-word;
    text-align: center;
  }

  &[data-has-background-image="false"][data-has-font-awesome-icon="false"] .option-label {
    font-size: 18px;
  }

  &:link,
  &:visited {
    color: #000;
  }
}
