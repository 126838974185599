.components--surveys--bottom-bar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .next-previous-slide-container {
    font-size: 26px;
    font-weight: bold;
  }

  .progress-bar-container {
    flex-grow: 1;
    margin-right: 130px;
    margin-left: 130px;
  }

  .progress-bar-progress {
    height: 100%;
    border-radius: 18px;
    background-color: #4c93ff;
  }

  .progress-bar-outer {
    width: 100%;
    height: 38px;
    border-radius: 18px;
    background: rgba(#fff, .5);
  }
}
