.components--user--school-classes--digital-class-room--recommended {
  .content-container {
    position: relative;
    overflow: hidden;
    padding: 33px;
    border-radius: 12px;
    background-position: center center;
    background-size: cover;
    color: #fff;
  }

  .recommended-header {
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    .fa {
      margin-left: 8px;
    }
  }

  .process-about {
    margin-top: 27px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
  }

  .subject-container {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 52px;
    font-weight: bold;
    text-align: center;
  }

  .circles-container {
    display: flex;
    justify-content: center;
  }

  .circle-element {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #fff;
  }

  .circle-element-check-mark {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #4c93ff;
    font-size: 22px;
  }

  .completed-label,
  .ongoing-label {
    position: absolute;
    top: -30px;
    left: -90px;
    width: 200px;
    padding: 10px 0;
    background-color: #4c93ff;
    color: #fff;
    text-align: center;
    transform: rotate(-45deg);
    transform-origin: 100% 0;
  }
}
