.components--inputs--select {
  display: block;
  width: 100%;
  padding: 17px 26px;
  border: 0;
  border-radius: 0;
  background: transparent;
  color: #666;
  font-size: 17px;

  &:focus {
    outline: none;
  }
}
