.react-toggle {
  .react-toggle-track {
    background-color: #fff !important; // stylelint-disable-line declaration-no-important
  }

  .react-toggle-thumb {
    border-color: transparent !important; // stylelint-disable-line declaration-no-important
    background-color: #3092ff;
  }
}
