[data-route="play/school-class-check-in/team-surveys/completed"] {
  height: 100%;

  .completed-content-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .completed-content {
    display: flex;
    height: 100%;
    max-height: 792px;
    align-items: center;
    justify-content: center;
    padding: 32px;
    border-radius: 10px;
    margin: 20px;
    background-color: #4c93ff;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
  }
}
