.api-maker--table .actions-column .action-button {
  display: inline-block;
  padding: 3px 7px;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 11px;

  &:link,
  &:visited {
    color: #000;
  }
}
