.components--inputs--checkbox {
  position: relative;

  .fake-checkbox {
    width: 25px;
    height: 25px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    margin-right: 7px;
    box-shadow: 0 8px 10px 0 rgba(0 0 0 / 7%);
  }

  .check-icon {
    position: absolute;
    top: 6px;
    left: 4px;
    display: none;
    color: #fff;
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &:hover .fake-checkbox,
  input:focus ~ label .fake-checkbox,
  input:hover ~ label .fake-checkbox {
    background-color: #ccc;
  }

  input:checked ~ label .fake-checkbox .check-icon { // stylelint-disable-line selector-max-compound-selectors
    display: block;
  }

  input:checked ~ label .fake-checkbox {
    background-color: #2196f3 !important; // stylelint-disable-line declaration-no-important
  }
}
