.components--user--unicorn--info-icon {
  margin-left: auto;
  color: #1e3150;
  font-size: 30px;
}

.info-icon-hover-content {
  position: absolute;
  z-index: 996;
  width: 450px;
  padding: 15px 25px;
  border: 1px solid #000;
  border-radius: 6px;
  background: #fff;
}
