@use "stylesheets/variables" as *;

.routes--user--school-classes--digital-class-room {
  .action-container {
    display: flex;
    justify-content: center;

    @include up-from-md {
      justify-content: flex-end;
    }
  }

  .dog-image {
    position: absolute;
    bottom: -10px;
    left: -15px;

    width: 130px;
    height: 130px;

    background-repeat: no-repeat;
    background-size: contain;

    @include up-from-sm {
      width: 160px;
      height: 160px;
    }

    @include up-from-md {
      left: -30px;
      width: 200px;
      height: 200px;
    }

    @include up-from-lg {
      width: 260px;
      height: 260px;
    }
  }

  .instructions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    text-align: center;

    @include up-from-lg {
      margin-bottom: 80px;
    }

    @include up-from-xl {
      margin-bottom: 0;
    }

    @include up-from-xl {
      margin-left: 100px;
    }
  }

  .mood-column-with-dog {
    @include up-to-md {
      margin-top: 160px;
    }
  }

  .more-check-ins-needed-content {
    font-size: 26px;
    font-weight: bold;
  }

  .more-check-ins-needed-content,
  .three-check-ins-text {
    @include up-from-xl {
      margin-right: 70px;
      margin-left: 120px;
    }
  }

  .three-check-ins-text {
    max-width: 528px;
    color: #595b60;
    font-size: 32px;
  }

  .top-positive-column {
    padding: 50px;
    border-radius: 10px 0 0 10px;
    background-color: #4c93ff;
  }

  .top-negative-column {
    padding: 50px;
    border-radius: 0 10px 10px 0;
    background-color: #90b9fa;
  }

  .top-positive-negative-icon {
    color: #fff;
    font-size: 30px;
  }

  .top-positive-negative-label {
    margin-top: 15px;
    color: #fff;
    font-size: 24px;
    text-align: center;
  }

  .top-score-factor-name-container {
    text-align: center;
  }
}
