.components--inputs--input-container {
  .input-checkbox {
    display: flex;
    align-items: center;
  }

  .input-container-hint {
    margin-top: 4px;
    color: #666;
    font-size: 12px;
  }

  + .components--inputs--button,
  + .components--inputs--input-container {
    margin-top: 15px;
  }

  .input-container-legend {
    position: relative;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 8px 10px 0 rgba(0 0 0 / 7%);

    &[data-has-icon="true"] {
      padding-right: 15px;
    }

    .label-container {
      position: absolute;
      top: -7px;
      left: 18px;
      padding-right: 3px;
      padding-left: 3px;
      background: linear-gradient(180deg, transparent 37%, #fff 0, #fff 63%);
      color: #aaa;
      font-size: 12px;
    }

    .input-icon {
      position: absolute;
      top: 16px;
      right: 12px;
      color: #aaa;
      font-size: 21px;
    }
  }
}
