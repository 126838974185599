.components--nav--item {
  padding: 10px 14px;
  border-radius: 8px;
  background-color: #abbcd0;

  + .components--nav--item {
    margin-left: 12px;
  }

  &:link,
  &:visited {
    color: #fff;
  }

  &[data-active="true"] {
    background-color: #d0d5db;
  }
}
