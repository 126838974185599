@use "stylesheets/variables" as *;

.components--contents--presentation--absolute-container {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &[data-responsive="true"] {
    width: 100%;

    @include up-from-lg {
      min-width: 800px;
    }

    @include up-from-xl {
      min-width: 1000px;
    }
  }

  &[data-responsive="false"] {
    position: absolute;
    z-index: 0;
  }

  .absolute-container-inner {
    position: relative;
    height: 100%;

    &[data-horizontal-align="left"] {
      display: flex;
      justify-content: start;
      text-align: left;
    }

    &[data-horizontal-align="center"] {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    &[data-horizontal-align="right"] {
      display: flex;
      justify-content: end;
      text-align: right;
    }

    &[data-vertical-align="top"] {
      display: flex;
      align-items: start;
    }

    &[data-vertical-align="center"] {
      display: flex;
      align-items: center;
    }

    &[data-vertical-align="bottom"] {
      display: flex;
      align-items: end;
    }
  }
}
