.haya--typeahead > input,
.components--inputs--input {
  display: block;
  width: 100%;
  padding: 17px 26px;
  border: 0;
  border-radius: 0;
  background: transparent;
  color: #666;
  font-size: 17px;

  &:focus {
    outline: none;
  }

  &[data-resize="vertical"] { // stylelint-disable-line selector-no-qualifying-type
    resize: vertical;
  }
}
