.components--users--sign-in {
  .new-user-link {
    width: 100%;
    background-color: #007b2d;

    &:link,
    &:visited {
      color: #fff;
    }
  }

  .or-container {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}
