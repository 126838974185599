ul.pagination { // stylelint-disable selector-no-qualifying-type
  display: flex;
  padding-inline-start: 0;

  .page-item {
    display: list-item;
    padding: 9px 12px;
    background-color: #fff;
    list-style: none;

    &:first-child {
      border-radius: 7px 0 0 7px;
    }

    &:last-child {
      border-radius: 0 7px 7px 0;
    }

    + .page-item {
      border-left: 1px solid #f5f5f5;
    }

    &.active {
      font-weight: bold;
    }
  }
}
