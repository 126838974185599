@use "stylesheets/variables" as *;

[data-route="user/school-class-check-ins/lobby"] {
  width: 100%;
  min-height: 100%;

  .lobby-outer-container {
    width: 100%;
    min-height: 100%;
    padding: 25px;
  }

  .lobby-container {
    width: 100%;
    min-height: 100%;
  }

  .cancel-check-in-button {
    display: block;
    margin-top: 15px;
    text-decoration: underline;

    &:link,
    &:visited {
      color: #000;
    }
  }

  .check-in-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px;
    border-radius: 10px 10px 0 0;
    background-color: #f6f4f4;

    @include up-from-lg {
      border-radius: 10px 0 0 10px;
    }
  }

  .check-in-begins-container {
    font-size: 36px;
    font-weight: bold;
    text-align: center;

    @include up-from-md {
      font-size: 42px;
    }

    @include up-from-xl {
      font-size: 72px;
    }
  }

  .check-in-status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px;
    border-radius: 0 0 10px 10px;
    background-color: #4c93ff;

    @include up-from-lg {
      border-radius: 0 10px 10px 0;
    }
  }

  .qr-code-image {
    display: flex;
    width: 210px;
    height: 210px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-top: 12px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;

    @include up-from-lg {
      width: 292px;
      height: 292px;
      margin-top: 75px;
    }
  }

  .check-in-url-container {
    width: 100%;
    padding: 21px;
    border-radius: 40px;
    margin-top: 12px;
    background-color: #fff;
    color: #4c93ff;
    font-weight: bold;
    text-align: center;

    @include up-from-lg {
      font-size: 42px;
    }

    @include up-from-xl {
      padding: 21px 60px;
    }
  }

  .check-in-done-count-container {
    color: #fff;
    font-size: 90px;
    font-weight: bold;
    text-align: center;

    @include up-from-xl {
      font-size: 200px;
    }
  }

  .enter-code-text {
    margin-top: 12px;
    text-align: center;

    @include up-from-lg {
      margin-top: 67px;
      font-size: 26px;
    }
  }

  .is-done-container {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;

    @include up-from-xl {
      font-size: 72px;
    }
  }

  .close-woof-and-view-results-button-container {
    margin-top: 12px;
    text-align: center;

    @include up-from-xl {
      margin-top: 133px;
    }
  }

  .close-woof-and-view-results-button {
    font-weight: bold;
  }
}
