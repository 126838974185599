.component-bootstrap-card {
  padding: 10px;
  border-radius: 7px;
  background-color: #fff;

  .card-header {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}
