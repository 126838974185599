@use "stylesheets/variables" as *;

.components--card {
  border-radius: 20px;
  background-color: #eeeded;

  .card-children {
    background-color: #fff;

    @include up-to-md {
      padding: 35px;
    }

    @include up-from-md {
      padding: 50px 70px;
    }

    &[data-children-bottom-rounded="true"] {
      border-radius: 20px;
    }

    &[data-children-bottom-rounded="false"] {
      border-radius: 20px 20px 0 0;
    }
  }

  .card-footer {
    padding: 30px 70px;
    border-radius: 0 0 20px 20px;
  }

  .card-header {
    margin-bottom: 32px;
    font-size: 32px;
    text-align: center;
  }

  .card-sub-header {
    margin-bottom: 32px;
    font-size: 20px;
    text-align: center;
  }
}
