.components--user--school-classes--insights--score-factor-chart-slide {
  .score-factor-button {
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #d8d8d8;
    cursor: pointer;

    &[data-active="true"] {
      background-color: #4c93ff;
      color: #fff;
    }

    + .score-factor-button {
      margin-left: 18px;
    }
  }

  .score-factors-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
