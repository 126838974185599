@use "stylesheets/variables" as *;

.components--front-layout--top-menu {
  @include up-from-md {
    position: absolute;
    z-index: 1;
    right: 25px;
  }

  .top-menu-container {
    display: flex;
    width: 100%;
    justify-content: center;

    @include up-from-md {
      justify-content: space-between;
    }
  }
}
