@use "stylesheets/variables" as *;

.components--user--school-classes--insights--mood-bar-chart-slite {
  .chart-type-label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;

    &[data-chart-type-label-active="true"] {
      color: #3092ff;
    }

    &[data-chart-type-label-active="false"] {
      color: #666;
    }
  }

  .mood-bar-chart-card {
    padding: 60px 25px 45px 0;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 0 14px 0 rgba(0 0 0 / 10%);
  }

  .mood-bar-chart-flex-container {
    display: flex;
    width: 100%;

    @include up-to-md {
      flex-direction: column;
    }
  }

  .week-picker-container {
    @include up-to-md {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }
  }

  .mood-bar-chart-container {
    flex-grow: 1;

    @include up-from-md {
      margin-left: 25px;
    }

    @include up-from-lg {
      margin-left: 125px;
    }
  }

  .view-the-latest-three-woofs-button {
    padding: 20px 28px;
    border: 0;
    border-radius: 30px;
    background-color: #3092ff;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
