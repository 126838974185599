/* This file should be a .css file to also work in the mailer template. */

[data-class*="components--ckeditor--presentation"] p {
  font-size: 1.3em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-1 {
  font-size: 8em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-2 {
  font-size: 6em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-3 {
  font-size: 4.5em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-4 {
  font-size: 2.5em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-5 {
  font-size: 2em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-6 {
  font-size: 1.8em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-7 {
  font-size: 1.5em;
}

[data-class*="components--ckeditor--presentation"] .ckeditor-heading-8 {
  font-size: 1.2em;
}
