.components--popup-menu {
  position: absolute;
  z-index: 999;
  top: 12px;
  min-width: 230px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  font-size: 14px;
}

.components--popup-menu--menu-item {
  display: block;
  width: 100%;
  padding: 12px;

  &:link,
  &:visited {
    color: #685c5f;
  }
}

.components-popup-menu-link {
  &[data-align="left"] {
    .components--popup-menu {
      left: 12px;
    }
  }

  &[data-align="right"] {
    .components--popup-menu {
      right: 12px;
    }
  }
}
