$xs-from: 0px; // stylelint-disable-line length-zero-no-unit
$xs-to: 575px;
$sm-from: 576px;
$sm-to: 767px;
$md-from: 768px;
$md-to: 991px;
$lg-from: 992px;
$lg-to: 1199px;
$xl-from: 1200px;
$xl-to: 1399px;
$xxl-from: 1400px;

// stylelint-disable selector-no-qualifying-type

// SM
@mixin up-to-sm {
  @media (max-width: $xs-to) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (max-width: calc($xs-from + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

@mixin up-from-sm {
  @media (min-width: $sm-from) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (min-width: calc($sm-from + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

// MD
@mixin up-to-md {
  @media (max-width: $sm-to) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (max-width: calc($sm-to + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

@mixin up-from-md {
  @media (min-width: $md-from) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (min-width: calc($md-from + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

@mixin up-to-lg {
  @media (max-width: $md-to) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (max-width: calc($md-to + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

@mixin up-from-lg {
  @media (min-width: $lg-from) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (min-width: calc($lg-from + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

@mixin up-to-xl {
  @media (max-width: $lg-to) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (max-width: calc($lg-to + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}

@mixin up-from-xl {
  @media (min-width: $xl-from) {
    :not(body[data-side-bar-menu-opened="true"]) & {
      @content;
    }
  }

  @media (min-width: calc($xl-from + 250px)) {
    body[data-side-bar-menu-opened="true"] & {
      @content;
    }
  }
}
