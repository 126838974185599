@use "stylesheets/variables" as *;

.components--user--unicorn--filter {
  .date-from-container {
    @include up-to-lg {
      width: 50%;
      padding-right: 7px;
    }

    @include up-from-lg {
      width: 33%;
      padding-left: 15px;
    }

    @include up-from-xl {
      width: 195px;
      margin-left: 5px;
    }
  }

  .date-to-container {
    @include up-to-lg {
      width: 50%;
      padding-left: 7px;
    }

    @include up-from-lg {
      width: 33%;
      padding-left: 7px;
    }

    @include up-from-xl {
      width: 185px;
      margin-left: 5px;
    }
  }

  .school-class-select-container {
    width: 300px;

    @include up-to-lg {
      width: 100%;
      margin-bottom: 15px;
    }

    @include up-from-lg {
      width: 33%;
    }

    @include up-from-xl {
      width: 230px;
    }
  }

  .search-submit-container {
    @include up-to-xl {
      width: 100%;
    }

    @include up-from-xl {
      margin-left: 5px;
    }
  }

  .search-submit {
    @include up-to-xl {
      width: 100%;
      margin-top: 15px;
    }
  }
}
