@use "stylesheets/variables" as *;

.d-up-to-md-none {
  @include up-to-md {
    display: none;
  }
}

.d-up-from-md-none {
  @include up-from-md {
    display: none;
  }
}
