.components--school-classes--card {
  position: relative;
  padding: 50px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 10px 0 rgba(0 0 0 / 7%);

  .school-class-action-icon {
    &:link,
    &:visited {
      color: #999;
    }

    + .school-class-action-icon {
      margin-left: 10px;
    }
  }

  .school-class-card-actions-container {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .school-class-card-name-container {
    font-size: 28px;
    text-align: center;
  }

  .school-container {
    margin-top: 8px;
    color: #a6a7ab;
    text-align: center;
  }

  .go-to-my-insights-container {
    margin-top: 15px;
    text-align: center;
  }

  .go-to-my-insights-link {
    display: block;
    font-size: 13px;

    &:link,
    &:visited {
      text-decoration: underline;
    }
  }

  .teacher-circle-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .components--users--circle-icon {
    + .components--school-classes--card--add-class-role-circle-button,
    + .components--users--circle-icon {
      margin-left: 8px;
    }
  }
}
