@use "stylesheets/variables" as *;

.w-100 {
  width: 100%;
}

.w-up-to-sm-100 {
  @include up-to-sm {
    width: 100%;
  }
}

.w-up-to-md-100 {
  @include up-to-md {
    width: 100%;
  }
}

.w-up-to-lg-100 {
  @include up-to-lg {
    width: 100%;
  }
}
