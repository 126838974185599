@use "stylesheets/variables" as *;

.components--circle-icon-button {
  max-width: 130px;
  text-align: center;

  .icon-container {
    display: flex;
    width: 90px;
    height: 90px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include up-from-lg {
      width: 118px;
      height: 118px;
    }
  }

  .circle-icon-button-label {
    margin-top: 8px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    @include up-from-lg {
      font-size: 24px;
    }
  }

  &[data-color="blue"] {
    .icon-container {
      background-color: #4c93ff;
    }

    .circle-icon-button-label {
      color: #4c93ff;
    }
  }

  &[data-color="magenta"] {
    .icon-container {
      background-color: #d648dc;
    }

    .circle-icon-button-label {
      color: #d648dc;
    }
  }

  &[data-disabled="true"] {
    .icon-container {
      background-color: #cbd4e2;
    }

    .circle-icon-button-label {
      color: #7e8ea5;
    }
  }
}
