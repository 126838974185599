.components--users--circle-icon {
  display: flex;
  width: 41px;
  height: 41px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fc431b;
  color: #fff;
}
