.text-black {
  color: #000;

  &:link,
  &:visited {
    color: #000;

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

.text-nowrap {
  white-space: nowrap;
}

.text-white {
  color: #fff;

  &:link,
  &:visited {
    color: #fff;

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}
