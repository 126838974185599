.components--user--class-status--row {
  .expand-button-column {
    height: 100%;
  }

  .expand-button-container {
    display: flex;
    height: 100%;
    min-height: 71px;
    align-items: center;
    padding-left: 25px;
  }

  td:not(.expand-button-column) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  td:last-child {
    padding-right: 25px;
  }

  .row-label {
    font-weight: bold;
  }

  .circle-icon {
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .number-with-text {
    display: flex;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;

    &[data-color="blue"] {
      color: #4c93ff;

      .circle-icon {
        background-color: #4c93ff;
      }
    }

    &[data-color="red"] {
      color: #ea3160;

      .circle-icon {
        background-color: #ea3160;
      }
    }
  }

  .number-in-number-of-woofs {
    margin-right: 3px;
    font-size: 30px;
  }

  .expand-button {
    width: 20px;
    margin-right: 8px;

    &:link,
    &:visited {
      color: #5b5c64;
    }
  }

  .open-unicorn-button {
    padding: 6px 20px;
    border-radius: 10px;
    margin-left: auto;
    background-color: #4c93ff;

    &:link,
    &:visited {
      color: #fff;
    }
  }

  &[data-card-design="false"] {
    .row-label {
      font-size: 28px;
    }
  }

  &[data-card-design="true"] {
    margin-top: 10px;
    margin-bottom: 10px;

    .row-label {
      font-size: 20px;
    }

    .expand-button-container {
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
    }

    td:last-child {
      border-bottom-right-radius: 25px;
      border-top-right-radius: 25px;
    }

    &[data-has-unicorn="true"] {
      td:not(.expand-button-column) {
        background-color: #fff;
      }

      .expand-button-container {
        background-color: #fff;
      }
    }

    &[data-has-unicorn="false"] {
      td:not(.expand-button-column) {
        background-color: #fefefe;
        opacity: .6;
      }

      .expand-button-container {
        background-color: #fefefe;
        opacity: .6;
      }
    }
  }
}
